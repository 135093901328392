import React, { FC } from "react"
import { PageProps } from "gatsby"
import Box from "../components/box"
import Card from "../components/card"

import Heading from "../components/heading"
import Link from "../components/link"
import SEO from "../components/seo"
import Text from "../components/text"
import Wrapper from "../containers/wrapper"

interface Props extends PageProps {}

const ToolsPage: FC<Props> = () => (
    <Wrapper>
        <SEO title={`Pokemon Go Tools`} />
        <Heading as="h1" fontSize={8} mb={3}>All Tools</Heading>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gridTemplateRows="auto" gridGap={3}>
            <Link to="/type-effectiveness">
                <Card px={3} py={9} display="flex" justifyContent="center" alignItems="center">
                    <Heading as="h2" fontSize={3} m={0} textAlign="center">Type Effectiveness Calculator</Heading>
                </Card>
            </Link>
            <Link to="/trainer-levels">
                <Card px={3} py={9} display="flex" justifyContent="center" alignItems="center">
                    <Heading as="h2" fontSize={3} m={0} textAlign="center">Trainer Level Requirements</Heading>
                </Card>
            </Link>
            <Link to="/buddy-excitement">
                <Card px={3} py={9} display="flex" justifyContent="center" alignItems="center">
                    <Heading as="h2" fontSize={3} m={0} textAlign="center">Buddy Excitement</Heading>
                </Card>
            </Link>
        </Box>
    </Wrapper>
)

export default ToolsPage
